import ParseHTML from "../../utils/parser"
import IntroSinglePage from "../../components/IntroSinglePage";
import iconPin from "../../../img/icon_pin.svg";
import iconTel from "../../../img/icon_tel.svg";
import iconMail from "../../../img/icon_mail.svg";

import SimpleMap from "../../components/SimpleMapComponent";


function Contacts() {

  const title = <ParseHTML id={ "contact.pageTitle" } />


  return (
    <main class="page-contacts"> 
       <IntroSinglePage title={ title } />
      <section class="px-4 entry-content">
        <div class="grid size_6-5 max-w-site">
          <div>
            <div class="row-contact grid size_5-6 mb-7">
              <h2 class="h4"><b><ParseHTML id={ "contact.rowTitle1" } /></b></h2>

              <ul class="m0">
                <li class="footer_label-text">
                  <span class="icon"><img src={iconTel} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">095 2880137</span>
                </li>
              </ul>
            </div>

            <div class="row-contact grid size_5-6 mb-7">
              <h2 class="h4"><b><ParseHTML id={ "contact.rowTitle2" } /></b></h2>
              <ul class="m0">
                <li class="footer_label-text">
                  <span class="icon"><img src={iconMail} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">amministrazione@domino-design.it</span>
                </li>
                <li class="footer_label-text">
                  <span class="icon"><img src={iconMail} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">commerciale@domino-design.it</span>
                </li>
              </ul>
            </div>
            <div class="row-contact grid size_5-6 mb-7">
              <h2 class="h4"><b>
              <ParseHTML id={ "contact.rowTitle3" } /></b></h2>
              <ul class="m0">
                <li class="footer_label-text">
                  <span class="icon"><img src={iconPin} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">Zona Industriale, Strada G <br></br> 95048 Scordia (CT)</span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div >
                <SimpleMap />
            </div>
          </div>
        </div>
      </section>
    </main>


  );
}

export default Contacts;