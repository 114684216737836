//import React from "react";
//import GoogleMapReact from 'google-map-react';
//
//
//function SimpleMap(){
//
//  const defaultProps = {
//    center: {
//      lat: 37.291353,
//      lng: 14.854725
//    },
//    zoom: 16
//  };
//
//  return (
//    <div style={{ height: '100vh', width: '100%' }}>
//      <GoogleMapReact
//        bootstrapURLKeys={{ key: "AIzaSyDTkrDufp8IN8fI3ykxXjMh7wboOBoIk-8" }}
//        defaultCenter={defaultProps.center}
//        defaultZoom={defaultProps.zoom}
//      >
//      </GoogleMapReact>
//    </div>
//  );
//}
//
//export default SimpleMap;
//import React, { useEffect, useRef } from 'react';
//import { map, tileLayer, Browser } from 'leaflet';
//
//import './myMap.css';
//
//
//import React, { useState } from "react";
//import { MapContainer, TileLayer } from "react-leaflet";
//import "leaflet/dist/leaflet.css";
//
//const SimpleMap = () => {
//  const [center, setCenter] = useState({ lat: 37.291353, lng: 14.854725 });
//  const zoomLevel = 16;
//
//  return (
//    <MapContainer
//      center={center}
//      zoom={zoomLevel}
//      style={{ height: "400px", width: "100%" }}
//    >
//      <TileLayer
//        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.nppng"
//        attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
//      />
//    </MapContainer>
//  );
//};
//
//export default SimpleMap;


import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import icon from "../../img/marker.png";


const SimpleMap = () => {

  const center = { lat: 37.29159, lng: 14.85617};
  const zoomLevel = 16;

  const customIcon = L.icon({
    iconUrl: icon,
    iconSize: [48, 48]

  });


    return (
      <div style={{ position: 'relative' , overflow: 'hidden', zIndex: '0'  }}>
        <MapContainer center={center} zoom={zoomLevel} style={{ height: '500px', width: '100%' }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />

          <Marker position={center} icon={customIcon}  />

        </MapContainer>
      </div>
    );
};

export default SimpleMap;