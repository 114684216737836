import React from 'react';
import LoadImages from "../utils/LoadImages";

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
    // You can also perform additional actions here, such as displaying a user-friendly error message or resetting the state.
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render a fallback UI when an error occurs
      return (
        <div>
          <h1>Ops! Something went wrong </h1>
//          <h3>Try to visit this page later</h3>
//          <div><LoadImages src={"company-img1"} /></div>
        </div>


        );
    }

    // Render the child components if no error occurred
    return this.props.children;
  }
}

export default ErrorBoundary;