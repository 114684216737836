import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../../css/components/footer.css";
import logo from "../../img/logo.svg";
import iconPin from "../../img/icon_pin.svg";
import iconTel from "../../img/icon_tel.svg";
import iconMail from "../../img/icon_mail.svg";
import ParseHTML from "../utils/parser";

function Footer() {
 const [t,i18n] = useTranslation("global");
 const LANG = t("lng")
 const urlPrefix = ()=> '/'+LANG+'/';
 const urlWidget = t("url.quoterequest");



 return (
    <footer class="site-footer">
      <div class="bg-gradient px-4 widget-contract">
        <div class="py-2 widget-contract_cont max-w-site">
          <p><ParseHTML id={"footer.widgetContractTitle"} /> </p>
          <Link class="btn white" to={"/"+LANG+"/"+urlWidget} >
          <ParseHTML id={"footer.widgetContractButton"} /> </Link>
        </div>
      </div>
      <div class="px-4">
        <div class="max-w-site cont-footer">
          <div class="footer_col-3">
            <Link to={"/"+LANG }>
              <img class="footer_logo" src={logo} width="176" height="44" viewBox="0 0 176 44" fill="none" xmlns="http://www.w3.org/2000/svg" />
            </Link>
            <div class="footer_list-info">
              <h2 class="footer_label-info"><b>{ t("footer.where") }</b></h2>
              <ul>
                <li class="footer_label-text">
                  <span class="icon"><img src={iconPin} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">Zona Industriale, Strada G <br></br> 95048 Scordia (CT)</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer_col-3">
            <div class="footer_list-info">
              <h2 class="footer_label-info"><b>{ t("footer.contact") }</b></h2>
              <ul>
                <li class="footer_label-text">
                  <span class="icon"><img src={iconTel} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">095 2880137</span>
                </li>
                <li class="footer_label-text">
                  <span class="icon"><img src={iconMail} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">amministrazione@domino-design.it</span>
                </li>
                <li class="footer_label-text">
                  <span class="icon"><img src={iconMail} width="20" height="20" xmlns="http://www.w3.org/2000/svg" /></span>
                  <span class="text">commerciale@domino-design.it</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer_col-2">
            <ul class="footer-nav">
              <li class="item-nav">
                <Link to={ urlPrefix()+t("url.about") } >{t("navbar.about")}</Link>
              </li>
              <li class="item-nav">
                <Link to={ urlPrefix()+t("url.company") } >{t("navbar.company")}</Link>
              </li>
              <li class="item-nav">
                <Link to={ urlPrefix()+t("url.quoterequest") } >{t("navbar.quoterequest")}</Link>
              </li>
              <li class="item-nav">
                <Link to={ urlPrefix()+t("url.job") } >{t("navbar.job")}</Link>
              </li>
              <li class="item-nav">
                <Link to={ urlPrefix()+t("url.contacts") } >{t("navbar.contacts")}</Link>
              </li>
            </ul>
          </div>
          <div class="footer_col-2">
            <ul class="footer-nav secondary">
                <li class="item-nav">
                  <Link to={ urlPrefix()+t("url.privacy") }>Privacy policy</Link>
                </li>
                <li class="item-nav">
                   <Link to={ urlPrefix()+t("url.cookies") }>Cookies policy</Link>
                </li>
                 <li class="item-nav">
                    <Link to={ urlPrefix()+t("url.transparency") }>{t("footer.transparency")}</Link>
                 </li>
                <li class="item-nav">{'\n'}</li>
                <li>© 2023 Domino Design srl <br></br>P.IVA 05935800879</li>
              </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
