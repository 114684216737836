import JSZip from 'jszip';

const ZipFiles = async (files) => {
  try {
      const zip = new JSZip();
      await Promise.all(
        files.map(async (file) => {
            const blob = await file.arrayBuffer();
            // Get only the filename without directories
            const filename = file.name.split('/').pop();
            zip.file(filename, blob);
      }));
      return await zip.generateAsync({
        type: 'blob',
        mimeType: 'application/zip',
        compression: 'DEFLATE',
        compressionOptions: { level: 9 }
      });


  } catch (error) {
    console.error('Error zipping files:', error);
    throw error;
  }
};

export default ZipFiles;




//const fileToBlob = (file) => {
//  return file.arrayBuffer()
//    .then(buffer => new Blob([buffer], { type: file.type }));
//};





//
//    const zip = new JSZip();
//    // Create Blobs for each file and add them to the zip archive
//    await Promise.all(files.map(async (file, index) => {
//      const blob = await fileToBlob(file);
//      zip.file(file.name, blob);
//    }));

//    const zipBlob = await zip.generateAsync({ type: 'blob', mimeType: 'application/zip', compression: 'DEFLATE', compressionOptions: { level: 9 }});
//    return Array.from(new Uint8Array(zipBlob));
//    return zipBlob;

//const fileToBlob = (file) => {
//  return new Promise((resolve, reject) => {
//    const reader = new FileReader();
//    reader.onload = () => {
//      resolve(new Blob([reader.result], { type: file.type }));
//    };
//    reader.onerror = reject;
//    reader.readAsArrayBuffer(file);
//  });
//};

//const fileToByteArray = (file) => {
//  return new Promise((resolve, reject) => {
//    const reader = new FileReader();
//    reader.onload = () => {
//      const arrayBuffer = reader.result;
//      const byteArray = new Uint8Array(arrayBuffer);
//      resolve(byteArray);
//    };
//    reader.onerror = reject;
//    reader.readAsArrayBuffer(file);
//  });
//};

