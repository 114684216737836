import { Routes, Route  } from "react-router-dom";
import PublicPaths from "./PublicPaths";
import AgentsPaths from "./AgentPaths";
import AgentLayout from "../js/layout/private/AgentLayout";
import Layout from "../js/layout/Layout";

const RoutingPublicPaths = () => {

  //languages paths router
  return(
    <>
      <Route path={"/"} element={<Layout />} >
        { PublicPaths() }
      </Route>
      <Route path={"/it"} element={<Layout />} >
        { PublicPaths() }
      </Route>
      <Route path={"/en"} element={<Layout />} >
        { PublicPaths() }
      </Route>
    </>
  );
};

export default RoutingPublicPaths;
