import React, { useState,useEffect } from 'react';
import { Link, useNavigate,useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import { useAuth } from '../utils/AuthProvider';
import EmailConfirmationComponent from "./EmailConfirmationComponent";
import iconLoad from "../../img/form-loading.gif";
import iconClose from "../../img/icon_close.svg";
import iconInfo from "../../img/icon_info.svg";
import iconFile from "../../img/icon_doc.svg";
import iconPartner from "../../img/icon_partner.svg";
import iconMailOutline from "../../img/icon_mail.svg";
import iconPasswordOutline from "../../img/icon_password.svg";


import backendApi ,{getJwtToken} from "../utils/BackendApi.js"
import FileDropZone from './FileDropZoneComponent';
import zipFiles from "../utils/ZipFiles.js";
import jwt_decode from 'jwt-decode';
import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css'; // Importa i file CSS di react-datepicker
//import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import{ toast } from 'react-toastify';

const AgentSubmitDesignComponents = ({ idNegotiation, negotiationTitle, idDesign, designTitle,  onHideForm }) => {


    const [t,i18n] = useTranslation("global");
    const urlPrefix = () => '/' + t('lng') + '/';
    const navigate = useNavigate();

    //FORM VARIABLES
    const [title, setTitle] = useState('');
    const [designId, setDesignId] = useState(idDesign);

    const [quantity, setQuantity] = useState(1);
    const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    const [expectedClientDeliveryDate, setExpectedClientDeliveryDate] = useState(tomorrow);
    const [additionalNotes, setAdditionalNotes] = useState('');

    const [responseError, setResponseError] = useState('');


    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    }

    const handleExpectedClientDeliveryDateChange = (date) => {
        setExpectedClientDeliveryDate(date);
    }

    const handleNotesChange = (event) =>{
        setAdditionalNotes(event.target.value);
    }

    const notifySuccess = (message) =>  toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
    });

    const loadingSubmit = document.getElementById('loading-submit');
    const buttonSubmit = document.getElementById('submit');

    //Loading
    const setShowLoadingOnForm = (display=true) => {

          if(display && loadingSubmit!=null && buttonSubmit!=null){
            loadingSubmit.style.display = 'block';
            buttonSubmit.disabled = true;
          }else{
            if( loadingSubmit!=null && buttonSubmit!=null){
                loadingSubmit.style.display = 'none';
                buttonSubmit.disabled = false;
            }
          }
        }

    //** GESTIONE SCHEDE TECHNICHE DEGLI ACCESSORI
    const [droppedFiles, setDroppedFiles] = useState([]);
    const handleDrop = (newFiles) => {
        setDroppedFiles([...droppedFiles, ...newFiles]);
    };
    const removeFile = (indexToRemove) => {
        setDroppedFiles(droppedFiles.filter((file, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setResponseError('');
        setShowLoadingOnForm(true);

        try{
        const zippedFiles = await zipFiles( droppedFiles);

         const data = new FormData();
        data.append('negotiation_id', idNegotiation);
        data.append('designId', designId);
        data.append('quantity', quantity);
        data.append('request_delivery_by', expectedClientDeliveryDate.toISOString().split('T')[0]);
        data.append('additionalNotes', additionalNotes);
        //data.append('accessory_specs_files', zippedFiles);
        data.append('accessory_specs_files', zippedFiles, 'files.zip'); // Aggiungi il file zip al FormData
        console.log( "data to submit" );
        console.log( data );
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        const resp = await backendApi.post("api/order", data,config);

        notifySuccess(resp.data.message);

        }catch(error){
            setResponseError(error.response.data.message);
        }
        setShowLoadingOnForm(false);
        onHideForm(true);

    };

   //In base al ruolo può comparire un messaggio oppure un altro
   const [role, setRole] = useState('');
   const getRole = async () => {
           const token = await getJwtToken();
           setRole( jwt_decode(token)["Type"].toString() );
   };
   useEffect(() => { getRole(); }, []);

    return(
        <main class="page-login px-4 py-3">
            <div class="container-form">
                 <img className='icon-close' src={iconClose} onClick={ ()=> onHideForm(false) } width="32" height="32"/>
                 <h4 class="form_title">{t("agentSubmitDesignComponent.title")}</h4>
                 <div className="inner-form submit-design">
                    <div className="submit-design_row">
                        <div className='submit-design_col'><h5>{t("agentSubmitDesignComponent.project")}<span> { idNegotiation }</span>:</h5></div>
                        <div className='submit-desing_col'><span>{ negotiationTitle }</span></div>
                    </div>
                    <div className="submit-design_row">
                        <div className='submit-design_col'><h5>{t("agentSubmitDesignComponent.quotation")}<span> { designId }:</span></h5></div>
                        <div className='submit-desing_col'><span>{ designTitle }</span></div>
                    </div>
                    <div className="submit-design_row">
                        <div className='submit-design_col'><h5>{t("agentSubmitDesignComponent.quantity")}</h5></div>
                        <div className='submit-desing_col'><input id="quantity" value={quantity} type="number" name="quantity" min="1" required onChange={handleQuantityChange} /></div>
                    </div>
                    <div className="submit-design_row">
                        <div className='submit-design_col'><h5>{t("agentSubmitDesignComponent.expectedClientDeliveryDate")}</h5></div>
                        <DatePicker
                            selected={expectedClientDeliveryDate}
                            onChange={handleExpectedClientDeliveryDateChange}
                            dateFormat="dd/MM/yyyy"
                            minDate={tomorrow} // Imposta la data minima come domani
                            locale="en-GB"
                            placeholderText="Weeks start on Monday"
                        />
                    </div>
                    <div className="submit-design_row flex-column" >
                        <h5>Schede tecniche accessori</h5>
                        <FileDropZone onDrop={handleDrop}/>    
                        <div className="file-list">
                        {droppedFiles.length > 0 ? null : null}
                            <ul>{droppedFiles.map((file, index) => (
                                <li key={index} className="file-item">
                                <button onClick={() => removeFile(index)}>  <img src={iconClose} alt="Remove" /> </button>
                                <span>{file.name}</span>
                                </li>
                            ))}</ul>
                        </div>
                    </div>
                    <div className="submit-design_row flex-column">
                        <h5>{t("agentSubmitDesignComponent.additionalNotes")}</h5>
                        <textarea id="additionalNotes" name="additionalNotes"
                            placeholder={t("agentSubmitDesignComponent.additionalNotesPlaceholder")}
                            rows={3}
                            value={additionalNotes}
                            onChange={handleNotesChange} // Salva il contenuto nell'area di testo
                        />
                    </div>
                </div>
                <button id="submit" disabled={false} className='btn orange' onClick={handleSubmit}>{t("agentSubmitDesignComponent.submitButton")}</button>
                {role === 'Agent' ? (
                  <p className="submit-design_info-message p-small">
                    <img src={iconInfo} width={20} height={20} alt="Info Icon" />{' '}
                    {t("agentSubmitDesignComponent.infoMessage")}
                  </p>
                ) : null}
                {/* role==='Agent'? ( <p class="submit-design_info-message p-small"><img src={iconInfo} width={20} height={20}/> t("agentSubmitDesignComponent.infoMessage")</p>):()*/ }
                <img style={{ display: 'none' }} id="loading-submit" src={iconLoad} width="40" height="40"/>
                {responseError && <div className="notice error">{responseError}</div>}
           </div>
        </main>
    );
};

export default AgentSubmitDesignComponents;
